import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isNotEmpty} from '@app/utils/functions/common-functions';
import {UniqueIdService} from '@app/services/unique-id-service';
import {FenceResultPost} from '@app/rest-client/faxe/models/fence-result-post';
import {fetchAllRiderEvents} from '@app/store/faxe/rider-event/rider-event.actions';
import {selectRiderEvent} from '@app/store/faxe/rider-event/rider-event.selectos';

@Injectable({
  providedIn: 'root'
})
export class RiderEventFacadeService {

  constructor(
    private readonly store: Store<RootState>,
    private readonly uniqueIdService: UniqueIdService) {
  }

  fetchRiderEvent(issuer: string): Observable<FenceResultPost[]> {
    this.store.dispatch(
      fetchAllRiderEvents(
        {issuerStack: [issuer]}));

    return this.store.pipe(
      select(selectRiderEvent()),
      filter((data) => isNotEmpty(data)));
  }

}
