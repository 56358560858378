import {createSelector, MemoizedSelector} from '@ngrx/store';
import {Rider} from '@app/rest-client/faxe/models/rider';
import {getRiderState} from '@app/store/faxe/faxe-selectors';
import {selectAllRider} from '@app/store/faxe/rider/rider.reducer';



// eslint-disable-next-line @typescript-eslint/ban-types
export const selectRiderAll: MemoizedSelector<object, Rider[]> = createSelector(
  getRiderState,
  selectAllRider
);

export const selectRider = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, Rider[]> =>
  createSelector(
    getRiderState,
    selectRiderAll,
    (state, all) => all
  );


export const selectRiderById = (riderId: string):
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, Rider> =>
  createSelector(
    getRiderState,
    selectRiderAll,
    (state, all) => all
        .find(rider => rider.riderId === riderId)
  );

export const riderRecentlyOnTrack = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, Rider[]> =>
  createSelector(
    getRiderState,
    selectRiderAll,
    (state, all) => all
      .filter(rider => rider.isReportable)
  );

export const riderOnTrack = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, Rider[]> =>
  createSelector(
    getRiderState,
    selectRiderAll,
    (state, all) => all
      .filter(rider => rider.isOnTrack)
  );
