import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, mergeMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EMPTY, of,} from 'rxjs';
import {isNotEmpty, push} from '@app/utils/functions/common-functions';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {RidersService} from '@app/rest-client/faxe/services/riders.service';

import {onOperationStatus} from '../../client-slices/operation/operation.actions';
import {
  addManyRider,
  fetchAllRider,
  getRider,
  toggleHandled,
  updateToggleOne
} from '@app/store/faxe/rider/rider.actions';
import {selectRiderAll} from '@app/store/faxe/rider/rider.selectos';

@Injectable()
export class RiderEffects {

  fetchRiderEffect = createEffect(() => this.actions$.pipe(
      ofType(fetchAllRider),
      withLatestFrom(this.store.select(selectRiderAll)),
      exhaustMap(([action, state]) => {
        if (isNotEmpty(state)) {
          return state;
        }

        const actions = [];
        actions.push(getRider({
          issuerStack: push(action.issuerStack, 'fetchRiderEffect')
        }));
        return of(...actions);
      }),
      catchError(() => EMPTY)
    )
  );

  getRiderEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(getRider),
      exhaustMap((action) => this.service
        .riders()
        .pipe(mergeMap((response) => {
            const actions = [];
            actions.push(addManyRider(
              {models: response, issuerStack: push(action.issuerStack, 'getRiderEffect')}));
            return of(...actions);
          }),
          catchError((e) =>
            of(onOperationStatus({
                model: {
                  status: e.status,
                  operationId: 'riders',
                  callStack: push(action.issuerStack, 'getRiderEffect')
                }
              })
            )
          )
        )),
      catchError(() => EMPTY)
    )
  );

  toggleHandledEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleHandled),
      mergeMap((action) =>
        action.isHandled ?
          this.service.setUnhandled({riderId: action.riderId}) :
          this.service.setHandled({riderId: action.riderId})
            .pipe(mergeMap(() => {
                const actions = [];
                actions.push(updateToggleOne(
                  {riderId: action.riderId, issuerStack: push(action.issuerStack, 'getRiderEffect')}));
                return of(...actions);
              }),
              catchError((e) =>
                of(onOperationStatus({
                    model: {
                      status: e.status,
                      operationId: 'toggleHandledEffect',
                      callStack: push(action.issuerStack, 'toggleHandledEffect')
                    }
                  })
                )
              )
            )),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private service: RidersService,
    private actions$: Actions,
    private store: Store<FaxeStore>,
  ) {
  }
}
