import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, mergeMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EMPTY, of,} from 'rxjs';
import {isNotEmpty, push} from '@app/utils/functions/common-functions';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {onOperationStatus} from '../../client-slices/operation/operation.actions';
import {addManyRiderEvents, fetchAllRiderEvents, getRiderEvents} from '@app/store/faxe/rider-event/rider-event.actions';
import {selectRiderEventAll} from '@app/store/faxe/rider-event/rider-event.selectos';
import {RiderEventsService} from '@app/rest-client/faxe/services/rider-events.service';

@Injectable()
export class RiderEventEffects {

  entity = 'RiderEvent';

  fetchRiderEventEffect = createEffect(() => this.actions$.pipe(
      ofType(fetchAllRiderEvents),
      withLatestFrom(this.store.select(selectRiderEventAll)),
      exhaustMap(([action, state]) => {

        if (isNotEmpty(state)) {
          return state;
        }
        const actions = [];
        actions.push(getRiderEvents({
          issuerStack: push(action.issuerStack, `fetch${this.entity}Effect`)
        }));
        return of(...actions);
      }),
      catchError(() => EMPTY)
    )
  );

  getRiderEventEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(getRiderEvents),
      mergeMap((action) => this.service
        .riderevents()
        .pipe(mergeMap((response) => {
            const actions = [];
            actions.push(addManyRiderEvents(
              {models: response, issuerStack: push(action.issuerStack, `get${this.entity}Effect`)}));
            return of(...actions);
          }),
          catchError((e) =>
            of(onOperationStatus({
                model: {
                  status: e.status,
                  operationId: 'getRiderEventEffect',
                  callStack: push(action.issuerStack, 'getRiderEventEffect')
                }
              })
            )
          )
        )),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private service: RiderEventsService,
    private actions$: Actions,
    private store: Store<FaxeStore>,
  ) {
  }
}
