import {createAction, props} from '@ngrx/store';
import {RiderEvent} from '@app/rest-client/faxe/models/rider-event';

const prefix = '[FAXE-RIDER-EVENT]';

export const fetchAllRiderEvents = createAction(
  `${prefix} - fetch all`,
  props<{ issuerStack: string[] }>()
);

export const getRiderEvents = createAction(
  `${prefix} - get`,
  props<{ issuerStack: string[] }>()
);

export const addManyRiderEvents = createAction(
  `${prefix} - add many`,
  props<{ models: RiderEvent[]; issuerStack: string[] }>()
);
