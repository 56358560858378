/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RiderEvent } from '../models/rider-event';
import { RiderEventPost } from '../models/rider-event-post';

@Injectable({
  providedIn: 'root',
})
export class RiderEventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation riderevents
   */
  static readonly RidereventsPath = '/riderevents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riderevents()` instead.
   *
   * This method doesn't expect any request body.
   */
  riderevents$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RiderEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, RiderEventsService.RidereventsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RiderEvent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `riderevents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  riderevents(params?: {
  }): Observable<Array<RiderEvent>> {

    return this.riderevents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RiderEvent>>) => r.body as Array<RiderEvent>)
    );
  }

  /**
   * Path part for operation ridereventsHorseCombinationNumberPost
   */
  static readonly RidereventsHorseCombinationNumberPostPath = '/riderevents/{horseCombinationNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ridereventsHorseCombinationNumberPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ridereventsHorseCombinationNumberPost$Response(params: {
    horseCombinationNumber: number;
    body: RiderEventPost
  }): Observable<StrictHttpResponse<RiderEvent>> {

    const rb = new RequestBuilder(this.rootUrl, RiderEventsService.RidereventsHorseCombinationNumberPostPath, 'post');
    if (params) {
      rb.path('horseCombinationNumber', params.horseCombinationNumber, {"style":"simple"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RiderEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ridereventsHorseCombinationNumberPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ridereventsHorseCombinationNumberPost(params: {
    horseCombinationNumber: number;
    body: RiderEventPost
  }): Observable<RiderEvent> {

    return this.ridereventsHorseCombinationNumberPost$Response(params).pipe(
      map((r: StrictHttpResponse<RiderEvent>) => r.body as RiderEvent)
    );
  }

  /**
   * Path part for operation deleteRiderEvents
   */
  static readonly DeleteRiderEventsPath = '/riderevents/{riderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRiderEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRiderEvents$Response(params: {
    riderId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RiderEventsService.DeleteRiderEventsPath, 'delete');
    if (params) {
      rb.path('riderId', params.riderId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRiderEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRiderEvents(params: {
    riderId: string;
  }): Observable<void> {

    return this.deleteRiderEvents$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
