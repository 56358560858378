import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {DataItem} from '@app/components/shared/model/data-item';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {isNotEmpty} from '@app/utils/functions/common-functions';

@Component({
  selector: 'faxe-select-option-chip',
  templateUrl: './select-option-chip.component.html',
  styleUrls: ['./select-option-chip.component.scss'],
})
export class SelectOptionChipComponent<T> implements OnInit, OnDestroy {
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() placeholder: string;
  @Output() valueChange: EventEmitter<T[]> = new EventEmitter<T[]>();
  formGroup: UntypedFormGroup = this.fb.group({
    value: [[], null],
  });

  private unsubscribe$ = new Subject<void>();
  private mFormCtrName: string;
  private mRequired: boolean;
  private mDataSource: DataItem<T>[];
  private mSelected: DataItem<T>[];
  private mSelectedSet = false;

  constructor(private fb: UntypedFormBuilder) {
  }

  @Input() set required(value: boolean) {
    this.mRequired = value;
    if (!this.mSelected) {
      this.checkDefault(this.mDataSource);
    }
  }

  @Input() set dataSource(value: DataItem<T>[]) {
    this.mDataSource = value;
    this.checkDefault(value);
  }

  @Input() set selected(value: DataItem<T>[]) {
    this.mSelectedSet = true;
    const match: DataItem<T>[] = this.matchByKey(this.mDataSource, value);

    if (isNotEmpty(match)) {
      this.mSelected = value;
      this.patch(match);
    }
  }

  @Input() set selectedByKey(value: any[]) {
    this.mSelectedSet = true;
    const match: DataItem<T>[] = this.matchByKeyValue(this.mDataSource, value);

    if (isNotEmpty(match)) {
      this.mSelected = value;
      this.patch(match);
    }
  }

  @Input() set updateRequired(value: boolean) {
    this.mRequired = value;
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
  }

  @Input() set pushSelected(value: DataItem<T>) {
    if (!value) {
      return;
    }
    this.selected = this.formGroup.controls.value.value
      ? [...this.formGroup.controls.value.value, value]
      : [value];
  }

  @Input() set formCtrName(value: string) {
    this.mFormCtrName = value;
  }

  getDataSource(): DataItem<T>[] {
    return this.mDataSource;
  }

  getRequired(): boolean {
    return this.mRequired;
  }

  getformCtrName(): string {
    return this.mFormCtrName;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((data) => !!data && !!data.value)
      )
      .subscribe((data) => {
        this.valueChange.emit(data.value.map((i) => i.value));
      });

    this.parentFormGroup.addControl(this.getformCtrName(), this.formGroup);
  }

  checkDefault(value: DataItem<T>[]): void {
    const defaultValue: DataItem<T>[] = this.findDefault(value);
    if (defaultValue && defaultValue.length > 0) {
      this.selected = defaultValue;
    } else if (
      !this.mSelectedSet &&
      this.getRequired() &&
      value &&
      value.length === 1
    ) {
      this.selected = value;
    }
  }

  clearSelected(): void {
    this.mSelectedSet = false;
    this.mSelected = [];
    this.patch(this.mSelected);
  }

  onChange(): boolean {
    return this.mDataSource.length === 1;
  }

  compareFn(c1: DataItem<T>, c2: DataItem<T>): boolean {
    return c1 && c2 ? c1.key === c2.key : c1 === c2;
  }

  removeItem(item: DataItem<T>): void {
    const selectedIndex =
      this.formGroup.controls.value.value &&
      this.formGroup.controls.value.value.indexOf(item);
    if (selectedIndex > -1) {
      const newValue = this.formGroup.controls.value.value.slice();
      newValue.splice(selectedIndex, 1);
      this.formGroup.controls.value.setValue(newValue);
    }
  }

  findDefault(value: DataItem<T>[]): DataItem<T>[] {
    return value?.filter((v) => v.default === v.key);
  }

  matchByKey(left: DataItem<T>[], right: DataItem<T>[]): DataItem<T>[] {
    if (!left || !right) {
      return undefined;
    }
    return left?.filter((l) => right.find((r) => l.key === r.key));
  }

  matchByKeyValue(left: DataItem<T>[], right: any[]): DataItem<T>[] {
    if (!left || !right) {
      return undefined;
    }
    return left?.filter((l) => right.find((r) => l.key === r));
  }

  patch(t: DataItem<T>[]): void {
    this.formGroup.controls.value.patchValue(t);
    if (t) {
      this.valueChange.emit(t.map((v) => v.value));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
