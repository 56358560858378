import {RiderView} from '@app/services/rider-view/model/rider-view';
import {isNotEmpty} from '@app/utils/functions/common-functions';
import {Fence} from '@app/rest-client/faxe/models/fence';


export const getRiderLastFenceSequence = (riderView: RiderView): number => {
  const sorted = riderView
    ?.fenceResults
    .slice()
    ?.sort((a, b) => a.sequence - b.sequence);

  if (isNotEmpty(sorted)) {
    return sorted[0]?.sequence;
  }
  return 0;
};

export const getRiderLastFenceName = (riderView: RiderView): string => {
  const sorted = riderView
    ?.fenceResults
    .slice()
    ?.sort((a, b) => b.sequence - a.sequence);

  if (isNotEmpty(sorted)) {
    return sorted[0].fenceName;
  }
  return undefined;
};

export const hasAllResult= (riderView: RiderView, selectedFenses: Fence[]): boolean => {
  const fenseResultsName = riderView?.fenceResults?.map(fr => fr.fenceName);
  const match = selectedFenses?.filter(sf => fenseResultsName.find(frn => frn === sf.fenceName));
  return match?.length === selectedFenses?.length;
};

