import {createAction, props} from '@ngrx/store';
import {SignalRStatus} from '@app/store/client-slices/signal-r/model/signal-r-status';

const prefix = '[FAXE-SYS-SIGNAL-R]';



export const onSignalRStatus = createAction(
  `${prefix} - onSignal-RStatus`,
  props<{ model: SignalRStatus }>()
);

export const onSignalVersionDiff = createAction(
  `${prefix} - onSignal-RVersionDiff`,
  props<{ multiMessageEnum: string; currentVersion: number }>()
);


