<h3 class="header">Välj ryttare</h3>
<faxe-chip-list #riderComponent
                class="rider"
                enableInput="true"
                inputHeading="Ny ryttare..."
                [parentFormGroup]="formGroup"
                formCtrlName="rider"
                [itemsSet]="riderDataItem"
                (itemSelectedEvent)="onRiderSelected($event)"
                (lastItemUnSelectedEvent)="onRiderLastUnSelected()"
></faxe-chip-list>
