import {RiderEventType} from '@app/constants/rider-event-type';
import {RiderEvent} from '@app/rest-client/faxe/models/rider-event';
import {RiderView} from '@app/services/rider-view/model/rider-view';

export const riderEventTimestamp = (riderEvents: RiderEvent[], eventType: RiderEventType): string =>
  riderEvents?.find(r => r.eventType === eventType)?.eventTimestamp;

export const timeString = (timeStamp: string): string => {
  const date = new Date(timeStamp + 'Z');
  return isValidDate(date) ? date.toLocaleTimeString() : undefined;
};

export const raceDuration = (riderView: RiderView): string => {
  const start = riderEventTimestamp(riderView.riderEvents, RiderEventType.started);

  if (start) {
    const finish = riderEventTimestamp(riderView.riderEvents, RiderEventType.finished);
    if (finish) {
      return duration(start, finish);
    }

    return duration(start, formatDate(new Date()));
  }
  return undefined;
};

export const duration = (start: string, finish: string): string => {
  const startDate = new Date(start);
  const finishDate = new Date(finish);

  if (isValidDate(startDate) && isValidDate(finishDate)) {
    return formatTimeDifference(startDate, finishDate);
  } else {
    return '';
  }
};

export const formatDate = (date: Date): string => {
  const pad = (num: number): string => num.toString().padStart(2, '0');
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-indexed in JavaScript
  const day = pad(date.getDate());
  const hours = pad(date.getUTCHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const formatTimeDifference = (startDate: Date, endDate: Date): string => {
  const diffInMilliseconds = endDate.getTime() - startDate.getTime();
  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)) + (hours * 60);
  const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);
  //const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
};


const isValidDate = (d: Date) => !!d && !isNaN(d.getTime());
