import {Injectable} from '@angular/core';
import {selectRider} from '@app/store/faxe/rider/rider.selectos';
import {combineLatestWith, Observable} from 'rxjs';
import {RiderView} from '@app/services/rider-view/model/rider-view';
import {selectFenceResult} from '@app/store/faxe/fence-result/fence-result.selectos';
import {map} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {Rider} from '@app/rest-client/faxe/models/rider';
import {FenceResult} from '@app/rest-client/faxe/models/fence-result';
import {selectRiderEvent} from '@app/store/faxe/rider-event/rider-event.selectos';
import {RiderEvent} from '@app/rest-client/faxe/models/rider-event';
import {RiderFacadeService} from '@app/services/rider/riders-facade.service';
import {FenceResultFacadeService} from '@app/services/fence-result/fence-result-facade.service';
import {RiderEventFacadeService} from '@app/services/rider-event/rider-event-facade.service';

@Injectable({
  providedIn: 'root'
})
export class RiderViewService {

  constructor(private store: Store<RootState>,
              private readonly riderService: RiderFacadeService,
              private readonly fenceResultService: FenceResultFacadeService,
              private readonly riderEventService: RiderEventFacadeService) {
  }

  public selectRiderViews(isReportable: boolean): Observable<RiderView[]> {
    this.riderService.fetchRiders('RiderViewService', false);
    this.fenceResultService.fetchFenceResult('RiderViewService');
    this.riderEventService.fetchRiderEvent('RiderViewService');

    return this.selectRider()
      .pipe(combineLatestWith(this.selectFenceResult(), this.selectRiderEvent()),
        map(([riders, fenceResults, riderEvents]) => this.map(isReportable, riders, fenceResults, riderEvents))
      );
  }

  private selectRider(): Observable<Rider[]> {
    return this.store.pipe(
      select(selectRider()));
  }

  private selectFenceResult(): Observable<FenceResult[]> {
    return this.store.pipe(
      select(selectFenceResult()));
  }

  private selectRiderEvent(): Observable<RiderEvent[]> {
    return this.store.pipe(
      select(selectRiderEvent()));
  }

  private map(isReportable: boolean, riders: Rider[], fenceResults: FenceResult[], riderEvents: RiderEvent[] ): RiderView[] {
    console.log('R ' + riders?.length, 'FR ' + fenceResults?.length, 'RE ' + riderEvents?.length );
    return riders
      .filter(rider => rider.isReportable === isReportable)
      .map(rider => ({
      ...rider,
      fenceResults: fenceResults.filter(fr => fr.riderId === rider.riderId),
      riderEvents: riderEvents.filter(re => re.riderId === rider.riderId)
    }));
  }
}
