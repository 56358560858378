import {createSelector, MemoizedSelector} from '@ngrx/store';
import {geRiderEventsState} from '@app/store/faxe/faxe-selectors';
import {RiderEvent} from '@app/rest-client/faxe/models/rider-event';
import {selectAllRiderEvent} from '@app/store/faxe/rider-event/rider-event.reducer';



// eslint-disable-next-line @typescript-eslint/ban-types
export const selectRiderEventAll: MemoizedSelector<object, RiderEvent[]> = createSelector(
  geRiderEventsState,
  selectAllRiderEvent
);

export const selectRiderEvent = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, RiderEvent[]> =>
  createSelector(
    geRiderEventsState,
    selectRiderEventAll,
    (state, all) => all
  );



