import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {RiderEvent} from '@app/rest-client/faxe/models/rider-event';
import {addManyRiderEvents} from '@app/store/faxe/rider-event/rider-event.actions';

export const identifier = (a: RiderEvent): number => a.riderEventId;

export const adapter: EntityAdapter<RiderEvent> = createEntityAdapter<RiderEvent>({
  selectId: identifier,
});

export type RiderEventEntityState = EntityState<RiderEvent>;

export const initialState: RiderEventEntityState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(addManyRiderEvents, (state, {models}) => adapter.addMany(models, state)),
);

export const riderEventReducer: (state: (RiderEventEntityState | undefined), action: Action) =>
  EntityState<RiderEvent> = (state: RiderEventEntityState | undefined, action: Action) =>
  reducer(state, action);

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllRiderEvent = selectAll;


