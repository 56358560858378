import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {ResultCode} from '@app/rest-client/faxe/models/result-code';
import {addManyResultCodes} from '@app/store/faxe/results-code/result-code.actions';

export const identifier = (a: ResultCode): number => a.resultCodeId;

export const adapter: EntityAdapter<ResultCode> = createEntityAdapter<ResultCode>({
  selectId: identifier,
});

export type ResultCodeEntityState = EntityState<ResultCode>;

export const initialState: ResultCodeEntityState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(addManyResultCodes, (state, {models}) => adapter.addMany(models, state)),
);

export const resultCodeReducer: (state: (ResultCodeEntityState | undefined), action: Action) =>
  EntityState<ResultCode> = (state: ResultCodeEntityState | undefined, action: Action) =>
  reducer(state, action);

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllResultCode = selectAll;


