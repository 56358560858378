import {createAction, props} from '@ngrx/store';
import {Rider} from '@app/rest-client/faxe/models/rider';

const prefix = '[FAXE-RIDER]';

export const fetchAllRider = createAction(
  `${prefix} - fetch all`,
  props<{ issuerStack: string[] }>()
);

export const getRider = createAction(
  `${prefix} - get all`,
  props<{ issuerStack: string[] }>()
);

export const addManyRider = createAction(
  `${prefix} - add many`,
  props<{ models: Rider[]; issuerStack: string[] }>()
);

export const updateToggleOne = createAction(
  `${prefix} - upset many`,
  props<{ riderId: string; issuerStack: string[] }>()
);

export const upsetOne = createAction(
  `${prefix} - upset one`,
  props<{ model: Rider; issuerStack: string[] }>()
);

export const removeAll = createAction(
  `${prefix} - remove all`,
  props<{ issuerStack: string[] }>()
);

export const toggleHandled = createAction(
  `${prefix} - toggleHandled`,
  props<{ riderId: string; isHandled: boolean; issuerStack: string[] }>()
);
