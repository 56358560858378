import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {faxeReducers} from '@app/store/faxe/faxe-store-model';
import {FenceResultEffects} from '@app/store/faxe/fence-result/fence-result.effects';
import {FenceEffects} from '@app/store/faxe/fences/fence.effects';
import {ResultCodeEffects} from '@app/store/faxe/results-code/result-code.effects';
import {RaceClassEffects} from '@app/store/faxe/race-class/race-class.effects';
import {ClubLogoEffects} from '@app/store/faxe/club-logo/club-logo.effects';
import {RiderEventEffects} from '@app/store/faxe/rider-event/rider-event.effects';
import {RiderEffects} from '@app/store/faxe/rider/rider.effects.';
import {SignalRStoreEffects} from "@app/store/client-slices/signal-r/signal-r-store.effects";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature('faxe', faxeReducers),
    EffectsModule.forFeature([
      RiderEffects,
      RiderEventEffects,
      FenceResultEffects,
      FenceEffects,
      ResultCodeEffects,
      RaceClassEffects,
      ClubLogoEffects,
      SignalRStoreEffects
    ]),
  ],
})
export class FaxeStoreModule {}
