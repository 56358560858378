import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {Rider} from '@app/rest-client/faxe/models/rider';
import {addManyRider, removeAll, upsetOne} from '@app/store/faxe/rider/rider.actions';

export const identifier = (rdier: Rider): string => rdier.riderId;

export const adapter: EntityAdapter<Rider> = createEntityAdapter<Rider>({
  selectId: identifier,
});

export type RiderEntityState = EntityState<Rider>;

export const initialState: RiderEntityState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(removeAll, (state) => adapter.removeAll(state)),
  on(addManyRider, (state, {models}) => adapter.addMany(models, state)),
  on(upsetOne, (state, {model}) => adapter.upsertOne(model, state)),
);

export const riderReducer: (state: (RiderEntityState | undefined), action: Action) =>
  EntityState<Rider> = (state: RiderEntityState | undefined, action: Action) =>
  reducer(state, action);

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllRider = selectAll;


