import {createSelector, MemoizedSelector} from '@ngrx/store';
import {getClientSlicesStore} from '@app/store/client-slices/client-slices-selectors';

export const getSignalRStoreState = createSelector(
  getClientSlicesStore,
  (clientStore) => clientStore.signalR
);

export const getSignalRStoreStatus = createSelector(
  getSignalRStoreState,
  (signalRData) => signalRData
);

export const selectSignalRStatus = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, boolean> =>
  createSelector(
    getSignalRStoreState,
    () =>
      true
  );




